<template>
  <div class="createcircle">
    <div class="createcircle_center">
      <header>
        <p>建立教研圈</p>
      </header>
      <main>
        <el-form ref="upformRef" :rules="upRules" :model="infoForm" label-width="150px">
          <!--  -->
          <el-form-item label="教研圈名称" prop="title">
            <el-input v-model="infoForm.title" size="mini" clearable></el-input>
          </el-form-item>
          <!--  -->
          <el-form-item label="学段" prop="period">
            <el-select v-model="infoForm.period" multiple placeholder="请选择" @change="selectAlls" size="mini">
              <!-- style="position: absolute;right:-4px;top:0px; color: #409EFF; z-index: 999;" -->
              <el-option v-for="item in optionPeriod" :key="item.id" :label="item.name" :value="item.id"></el-option>
              <el-option label="全选" value="all"></el-option>
            </el-select>
          </el-form-item>
          <!--  -->
          <el-form-item label="年级" prop="grade">
            <el-select v-model="infoForm.grade" multiple placeholder="请选择" @change="selectAll" ref="gradeInfoRef" @click.native="gradeinfo" size="mini">
              <!-- collapse-tags -->
              <!-- style="position: absolute;right:-4px;top:0px; color: #409EFF; z-index: 999;" -->
              <el-option label="全选" value="all"></el-option>
              <el-option v-for="item in optionGrade" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!--  -->
          <el-form-item label="学科类型" prop="style">
            <el-select v-model="infoForm.style" placeholder="请选择" @change="selectStyle" clearable size="mini">
              <el-option v-for="item in optionStyle" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!--  -->
          <el-form-item label="学科" prop="subject">
            <el-select
              v-model="infoForm.subject"
              placeholder="请选择"
              collapse-tags
              clearable
              ref="subjectInfoRef"
              @click.native="subjectinfo"
              size="mini"
            >
              <!-- style="position: absolute;right:-4px;top:0px; color: #409EFF; z-index: 999;" -->
              <!-- <el-option label="全选" value="all"></el-option> -->
              <el-option v-for="item in optionSbject" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!--  -->
          <el-form-item label="简介">
            <el-input type="textarea" v-model="infoForm.introduction" :rows="5" style="width: 500px;"></el-input>
          </el-form-item>
          <classcover @pirctureUrl="editpircture" :previewImg="previewImg"></classcover>
          <el-form-item>
            <el-button type="primary" @click="goback" size="mini">返回</el-button>
            <el-button type="primary" @click="nextstep" size="mini">完成</el-button>
          </el-form-item>
        </el-form>
      </main>
    </div>
  </div>
</template>
<script>
// import classcover from "./component/classcover.vue";
import classcover from "@/components/classcover.vue";
export default {
  components: {
    classcover,
  },
  data() {
    return {
      // -------基本信息
      // 基本信息表单数据
      infoForm: {
        title: null, //课程名称
        period: [], //学段
        grade: [], //年级
        style: null, //学科类型
        subject: null, //学科
        files: null, //图片文件---图片id
        introduction: null, //简介
      },
      // 基本信息表单规则
      upRules: {
        title: [{ required: true, message: "请输入课程标题", trigger: "blur" }],
        period: [{ required: true, message: "请选择学段", trigger: "change" }],
        grade: [{ required: true, message: "请选择年级", trigger: "change" }],
        style: [
          { required: true, message: "请选择学科类型", trigger: "change" },
        ],
        subject: [{ required: true, message: "请选择学科", trigger: "change" }],
      },
      // 学段
      optionPeriod: null,
      //年级数据
      optionGrade: null,
      //学科类型
      optionStyle: null,
      // 学科
      optionSbject: null,

      // 防止重复提交
      //  loading: false,
      // 图片预览
      previewImg: null,
    };
  },
  created() {
    // console.log(this.$route.query.id, "classRaw");
    // this.courseId = this.$route.query.id;
    this.queryclassRaw();
    this.queryPeriod();
    this.querySubjectType();
  },
  methods: {
    // 编辑图片
    editpircture(data) {
      this.infoForm.files = data;
    },
    // 编辑课程的raw获取
    async queryclassRaw() {
      console.log(this.$route.query.id == null, "this.courseId");
      if (this.$route.query.id == null) {
        return;
      } else {
        let idlist = this.$route.query.id;
        let from = new FormData();
        from.append("circleId", idlist);
        await this.$Http.queryCricleById(from).then((res) => {
          if (res.status == 200) {
            console.log(res, "编辑教研圈");
            this.dealClassRaw(res.data);
          }
        });
      }
    },
    // 数据处理
    async dealClassRaw(data) {
      console.log(data, "数据");
      this.infoForm.title = data.cricleName;
      this.infoForm.introduction = data.content;
      this.previewImg = data.logoMsg.url;
      this.infoForm.files = data.logoMsg.id;
      let aPeriod = [];
      let aGrade = [];
      let aStyle = null;
      let aSubject = null;
      //获取学科信息
      this.infoForm.style = data.subjectTypeList[0].id;
      this.querySubject(data.subjectTypeList[0].id).then(() => {
        this.infoForm.subject = data.subjectList[0].id;
      });
      // 获取年级
      let aa = [];
      for (let x = 0; x < data.periodList.length; x++) {
        aa.push(data.periodList[x].id);
      }
      this.infoForm.period = aa;
      this.queryGrade(aa).then(() => {
        let aaa = [];
        for (let y = 0; y < data.gradeList.length; y++) {
          aaa.push(data.gradeList[y].id);
        }
        this.infoForm.grade = aaa;
      });
      return;
    },
    //-----基本信息获取数据
    //学段
    async queryPeriod() {
      const { data, status } = await this.$Http.queryPeriod();
      if (status == 200) {
        this.optionPeriod = data;
        // console.log(data, status, "获取学段类型");
      }
    },
    //年级
    async queryGrade(id) {
      let idList = [];
      if (id == null || id == 0) {
        idList = [];
      } else {
        if (typeof id === "number") {
          idList.push(id);
        } else if (typeof id == "object") {
          idList = id;
        }
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: idList,
      });
      if (status == 200) {
        this.optionGrade = data;
        // console.log(data, status, "获取年级类型");
      }
    },
    //学科类型
    async querySubjectType() {
      const { data, status } = await this.$Http.querySubjectType();
      if (status == 200) {
        this.optionStyle = data;
        // console.log(data, status, "获取学科类型");
      }
    },
    // 学科
    async querySubject(id) {
      let idList = [];
      if (id == null || id == 0) {
        idList = [];
      } else {
        if (typeof id === "number") {
          idList.push(id);
        } else if (typeof id == "object") {
          idList = id;
        }
      }
      const { data, status } = await this.$Http.querySubject({
        idList: idList,
      });
      if (status == 200) {
        this.optionSbject = data;
        // console.log(data, status, "获取学科");
      }
    },

    // ---基本信息提交数据
    // 基本信息数据保存
    async saveCricle() {
      let aData = this.infoForm;
      let aa = []; //存学科类型id
      let bb = []; //存学科id
      aa.push(aData.style);
      bb.push(aData.subject);
      const { data, status } = await this.$Http.saveCricle({
        // courseId: this.courseId, //课程id
        cricleName: aData.title, //课程标题
        periodIdList: aData.period, //学段id集合
        gradeIdList: aData.grade, //年级id集合
        // subjectTypeIdList: aData.style, //	学科类型id
        // subjectIdList: aData.subject, //	学科id
        subjectTypeIdList: aa, //	学科类型id
        subjectIdList: bb, //	学科id
        content: aData.introduction, //简介
        logoId: aData.files, //图片地址
      });
      if (status == 200) {
        // this.activeName = "second";
        console.log(data, status, "完成教研圈信息");
        this.$router.push({
          name: "13-1",
        });
        // this.courseId = data;
        // this.viewChaper();
      }
    },
    async updateCircle() {
      let aData = this.infoForm;
      let aa = []; //存学科类型id
      let bb = []; //存学科id
      aa.push(aData.style);
      bb.push(aData.subject);
      const { data, status } = await this.$Http.updateCircle({
        id: this.$route.query.id,
        // courseId: this.courseId, //课程id
        cricleName: aData.title, //课程标题
        periodIdList: aData.period, //学段id集合
        gradeIdList: aData.grade, //年级id集合
        // subjectTypeIdList: aData.style, //	学科类型id
        // subjectIdList: aData.subject, //	学科id
        subjectTypeIdList: aa, //	学科类型id
        subjectIdList: bb, //	学科id
        content: aData.introduction, //简介
        logoId: aData.files, //图片地址
      });
      if (status == 200) {
        // this.activeName = "second";
        console.log(data, status, "完成教研圈信息");
        this.$router.push({
          name: "13-1",
        });
        // this.courseId = data;
        // this.viewChaper();
      }
    },
    // 完成 --提交前的判断 --确认提交
    nextstep() {
      console.log(this.infoForm, "infoForm");
      this.$refs["upformRef"].validate((valid) => {
        if (!valid) return;
        this.judgeGrade();
        if (this.aVal) return;
        // if (this.infoForm.files == null)
        //   return this.$message.error("请上传封面");
        if (this.$route.query.id == null) {
          this.saveCricle();
        } else {
          this.updateCircle();
        }
      });
    },
    //  判断学段和年级--提交前的判断
    judgeGrade() {
      let aData = this.infoForm.period;
      let bData = this.infoForm.grade;
      let cArray = [];
      aData.map((val) => {
        if (val == 1) {
          cArray.push(
            bData.some((val) => {
              return val == 1;
            })
          );
        } else if (val == 2) {
          cArray.push(
            bData.some((val) => {
              return val > 1 && val < 8;
            })
          );
        } else if (val == 3) {
          cArray.push(
            bData.some((val) => {
              return val > 7 && val < 11;
            })
          );
        } else if (val == 4) {
          cArray.push(
            bData.some((val) => {
              return val > 10 && val < 14;
            })
          );
        } else if (val == 5) {
          cArray.push(
            bData.some((val) => {
              return val > 28 && val < 32;
            })
          );
        }
      });
      const rData = cArray.every((item) => {
        return item == true;
      });
      if (!rData) {
        this.aVal = true;
        this.$message.error("存在已选学段，但没有对应年级!");
      } else {
        this.aVal = false;
      }
    },
    // 警告年级没有学段
    gradeinfo() {
      // console.log(this.meansHour, "焦点获取");
      if (this.optionGrade == null || this.optionGrade.length == 0) {
        this.$refs.gradeInfoRef.blur();
        this.$message({
          type: "warning",
          message: "请先选择学段信息",
        });
      }
    },
    // 警告学科没有学科类型
    subjectinfo() {
      // console.log(this.meansHour, "焦点获取");
      if (this.optionSbject == null || this.optionSbject.length == 0) {
        this.$refs.subjectInfoRef.blur();
        this.$message({
          type: "warning",
          message: "请先选择学科类型信息",
        });
      }
    },

    //返回
    goback() {
      // console.log("返回");
      this.$confirm("是否确定返回？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.$store.commit("userinfo/editclassRaw", null);
          // this.$store.commit("userinfo/editshowupclass", false);
          this.$router.push({
            name: "13-1",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //------- 基本信息的数据处理
    //全选学段数据
    selectAlls(val) {
      // console.log(val, "学段");
      if (val.length == 0) {
        this.optionGrade = null;
        return;
      }
      if (val.length > 1) {
        val.sort((a, b) => {
          return a - b;
        });
      }
      let flag = false;
      for (var key of val) {
        //如果有all
        if (key == "all") {
          flag = true;
        }
      }
      if (flag) {
        //先置空数组
        this.infoForm.period = [];
        //所有的选项push到v-model的value的数组
        this.optionPeriod.forEach((item) => {
          this.infoForm.period.push(item.id);
        });
      }
      this.queryGrade(this.infoForm.period);
    },
    //全选年级数据
    selectAll(val) {
      // console.log(val, "val");
      if (val.length > 1) {
        val.sort((a, b) => {
          return a - b;
        });
      }
      let flag = false;
      for (var key of val) {
        //如果有all
        if (key == "all") {
          flag = true;
        }
      }
      if (flag) {
        //先置空数组
        this.infoForm.grade = [];
        //所有的选项push到v-model的value的数组
        this.optionGrade.forEach((item) => {
          this.infoForm.grade.push(item.id);
        });
      }
    },
    //选择学科类型
    selectStyle(val) {
      // console.log(val, "学科类型");
      if (val.length == 0) {
        this.optionSbject = null;
        return;
      }
      this.querySubject(val);
    },
  },
  watch: {
    "infoForm.period": {
      handler(newval, oldval) {
        // console.log(newval, oldval, "监听学段");
        if (newval.length < oldval.length) {
          let set1 = new Set(oldval);
          let set2 = new Set(newval);
          let diffrence = new Set([...set1].filter((x) => !set2.has(x)));
          let num = [...diffrence];
          let aData = this.infoForm.grade;
          let astart = [];
          aData.map((val, i) => {
            if (num == 1 && val == 1) {
              astart.push(i);
            } else if (num == 2 && val > 1 && val < 8) {
              astart.push(i);
            } else if (num == 3 && val > 7 && val < 11) {
              astart.push(i);
            } else if (num == 4 && val > 10 && val < 14) {
              astart.push(i);
            } else if (num == 5 && val > 28 && val < 32) {
              astart.push(i);
            }
          });
          aData.splice(astart[0], astart.length);
        }
      },
      deep: true,
    },
    "infoForm.grade": {
      handler(newval, oldval) {
        // console.log(newval, oldval, "监听年级");
        // console.log(this.infoForm.period, "监听xued");
        if (newval.length < oldval.length) {
          let set1 = new Set(oldval);
          let set2 = new Set(newval);
          let diffrence = new Set([...set1].filter((x) => !set2.has(x)));
          let num = [...diffrence];
          let aData = this.infoForm.period;
          let aend = [];
          newval.map((val, i) => {
            if (num == 1 && val == 1) {
              aend.push(i);
            } else if (num > 1 && num < 8 && val > 1 && val < 8) {
              aend.push(i);
            } else if (num > 7 && num < 11 && val > 7 && val < 11) {
              aend.push(i);
            } else if (num > 10 && num < 14 && val > 10 && val < 14) {
              aend.push(i);
            } else if (num > 13 && num < 17 && val > 13 && val < 17) {
              aend.push(i);
            }
          });
          if (aend.length == 0) {
            let aMessage = null;
            if (num == 1) {
              aMessage = "您已经移除了幼儿教育的最后一个年级";
            } else if (num > 1 && num < 8) {
              aMessage = "您已经移除了小学的最后一个年级";
            } else if (num > 7 && num < 11) {
              aMessage = "您已经移除了初中的最后一个年级";
            } else if (num > 10 && num < 14) {
              aMessage = "您已经移除了高中的最后一个年级";
            } else if (num > 13 && num < 17) {
              aMessage = "您已经移除了职教的最后一个年级";
            }
            this.$message.error(aMessage);
            aData.map((val, i) => {
              if (num == 1 && val == 1) {
                aData.splice(i, 1);
              } else if (num > 1 && num < 8 && val == 2) {
                aData.splice(i, 1);
              } else if (num > 7 && num < 11 && val == 3) {
                aData.splice(i, 1);
              } else if (num > 10 && num < 14 && val == 4) {
                aData.splice(i, 1);
              } else if (num > 13 && num < 17 && val == 5) {
                aData.splice(i, 1);
              }
            });
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.createcircle {
  .createcircle_center {
    background-color: #fff;
    header {
      padding: 25px 30px;
      border-bottom: 1px solid #d9d9d9;
      p {
        // width: 72px;
        // height: 24px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        // line-height: 21px;
      }
    }
    main {
      padding: 25px;
      .el-input {
        width: 500px;
      }
      .el-select {
        width: 500px;
      }
    }
    footer {
      padding: 0 0 20px 425px;
    }
  }
}
</style>